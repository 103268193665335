const directions_colors = 
{
    "A. BROUSSONET": "#6D9332",
    "A. L. JUSSIEU": "#265C5E",
    "AGROPOLIS": "#6D533D",
    "AIGUELONGUE": "#241DEA",
    "AIGUELONGUE JUSS": "#11F66B",
    "AIRES": "#F5A84E",
    "AL. DE L'EUROPE": "#331668",
    "ALBERT 1er": "#356D4E",
    "ALBERT DUBOUT": "#31CD30",
    "ALBINONI": "#FEDF8A",
    "ALCO": "#F3B7E4",
    "ALFRED NOBEL": "#6FF3B9",
    "ALLEE DU BOIS": "#255BB6",
    "ALPILLES": "#2A16F0",
    "AMBROISE PARE": "#AB8C94",
    "ANATOLE FRANCE": "#9080EA",
    "ANCIENNE GARE": "#9C02A9",
    "ANDRE MALRAUX": "#30F3E8",
    "ANTENNES": "#70B7EF",
    "ANTERRIEU": "#E972C7",
    "APOLLO": "#2EBAD2",
    "APOTHICAIRES": "#9796EF",
    "APPEL DU 18 JUIN": "#C7AE32",
    "AQUEDUC": "#54B023",
    "ARBOUSIERS": "#B8B199",
    "ARENES": "#8CD26D",
    "ARNEL": "#942A57",
    "ASTRUC": "#F662F6",
    "AUBE ROUGE": "#DD5F44",
    "AV. AGRICULTURE": "#9275AE",
    "AV. DE LA GARE": "#0FAEF6",
    "AV. DE TOULOUSE": "#DC1F0B",
    "AV. JUSTIN BEC": "#0E7D3A",
    "AVENUE DE ROME": "#71EC04",
    "BAGATELLE": "#6C856D",
    "BAILLARGUET": "#10EA8A",
    "BALEARES": "#B58973",
    "BARILS": "#28D5C0",
    "BAUMES": "#CC4F09",
    "BD DES ECOLES": "#DA96DD",
    "BD DU CHAPITRE": "#BC1A06",
    "BEAULIEU": "#51A733",
    "BEAUX-ARTS": "#B4D6D4",
    "BEL HORIZON": "#7232FC",
    "BELLEVUE": "#E58FD2",
    "BELVEDERE": "#1ACBD0",
    "BENGALIS": "#9A51BC",
    "BERTHELOT": "#F0ECBD",
    "BIBLIOTHEQUE": "#50E7AC",
    "BISTE": "#065A1F",
    "BOIRARGUES": "#EAFE4B",
    "BOUTONNET": "#5CB2A1",
    "BUGAREL": "#CF5E2D",
    "C. COMMERCIAL": "#373044",
    "CADUCEE": "#FEF99F",
    "CALADE": "#764663",
    "CAMPUS AGROPOLIS": "#2E3C77",
    "CAP ALPHA": "#A4A78C",
    "CAPRI": "#F88DA2",
    "CARRIERA": "",
    "CASTELNAU Z.A.": "#1C31F7",
    "CATALPAS": "#308DEA",
    "CAVE COOPERATIVE": "#7C0B47",
    "CAYLUS": "#81A7B1",
    "CELLENEUVE": "#F2EF25",
    "CENTRAYRARGUES": "#B52B54",
    "CENTRE": "#89EB41",
    "CENTURIONS": "#E19122",
    "CEREIREDE": "#9C2FBE",
    "CERVANTES": "#2E05E7",
    "CEVENNES": "#5C9518",
    "CH. DE GAULLE": "#22A010",
    "CHAMBERY": "#3C8C9D",
    "CHANTECLER": "#0E7C52",
    "CHANTERELLES": "#C3190B",
    "CHARLES FLAHAULT": "#ECBD95",
    "CHASSEURS": "#3B3BA3",
    "CHATAIGNIERS": "#BB3D46",
    "CHATEAU": "#1E26E9",
    "CHATEAU D'O": "#1A3987",
    "CHATEAU TERRAL": "#872529",
    "CHEMIN DE LATTES": "#0E120B",
    "CHEMIN DES PINS": "#FD5977",
    "CHEMIN DU CAMBAS": "#59EE35",
    "CHEMIN NEUF": "#66B8B8",
    "CHENE LIEGE": "#A3EF3E",
    "CHENES": "#776E21",
    "CHENG-DU": "#03F311",
    "CIGALE": "#7949ED",
    "CIMETIERE": "#D8CAA5",
    "CIMETIERE JACOU": "#F28546",
    "CINSAULTS": "#C80FCC",
    "CIRAD DE BAILL.": "#E1CB42",
    "CITE DE L'ARME": "#D8DED4",
    "CITE GELY": "#F63CAE",
    "CITE MION": "#F995C7",
    "CITE ROGER": "#D8197E",
    "CITE VALETTE": "#62F84A",
    "CL. DES GUILHEMS": "#ABB720",
    "CLAIRVAL": "#D7C52E",
    "CLG AIGUERELLES": "#0F0583",
    "CLINIQUE DU PARC": "#D239C2",
    "CLOLUS": "#14E02A",
    "CLOS DES PINS": "#5BCD2A",
    "CNRS": "#6AB0DE",
    "COEUR DE VILLE": "#C672A6",
    "COL. R. CHARLES": "#442933",
    "COLL PIERRE M.F.": "#8D9D72",
    "COLLEGE": "#856843",
    "COLLEGE BERANGE": "#7DB700",
    "COLLEGE CLAUDEL": "#C9DE8F",
    "COLLEGE GERMAIN": "#FD1D5E",
    "COLLEGE LES PINS": "#967201",
    "COLLEGE PAGNOL": "#F4E2D2",
    "COLVERTS": "#E9A916",
    "COMBE CHAUDE": "#76EBAD",
    "COMEDIE": "#A9ADD2",
    "COMTE DE NICE": "#336608",
    "COMTE TOULOUSE": "#ED104C",
    "COOP. OLEICOLE": "#F7BF63",
    "COQUELICOTS": "#A0A00F",
    "CORSE": "#B9EB62",
    "CORUM T1": "#72CFCD",
    "CORUM T2": "#578187",
    "COSTE ROUSSE": "#F0F4F5",
    "COUGOURLUDE": "#E89B7D",
    "COURPOUYRAN": "#63493F",
    "CRAPONNE": "#39F9EC",
    "CREDIT AGRICOLE": "#001993",
    "CROIX D'ARGENT": "#005217",
    "CROIX DE LAVIT": "#A17904",
    "CRX DES ROSIERS": "#ED6208",
    "CRX DU CAPITAINE": "#EB1BE0",
    "DAME D'ORCAS": "#AB34AA",
    "DE BOCAUD": "#1219F9",
    "DESCARTES": "#D28FA7",
    "DEVEZES": "#C1E3AA",
    "DISTILLERIE": "#196A98",
    "DOMENOVES": "#D43F15",
    "DORIA": "#47BA6D",
    "DUCQUE": "#312945",
    "ECOLE D'ARCHI.": "#399B8F",
    "ECOLE DE CHIMIE": "#9F63E6",
    "ECOLE GARDIOLE": "#70BCCD",
    "ECOLES": "#DC68AC",
    "ECOPOLE": "#4997B4",
    "ECUREUILS": "#01E369",
    "EGLANTIERS": "#83749F",
    "EGLISE": "#E06F9A",
    "ENTREPRISES": "#A1F9D4",
    "ERABLES": "#366E5E",
    "ESPERANCE": "#39C62C",
    "ESTANOVE": "#B4AF0F",
    "ETOILE": "#31497E",
    "EUGENE BATAILLON": "#E38738",
    "EUREKA": "#F2CBF7",
    "EUROMEDECINE": "#4F203C",
    "EVARISTE GALOIS": "#6E9BAB",
    "F. COUPERIN": "#2EB75B",
    "F. FABREGE": "#5C4FAE",
    "FAC DE PHARMACIE": "#91262D",
    "FENOUILLET": "#D030E0",
    "FERNAND ROUCHE": "#7D2FC1",
    "FIGAIRASSE": "#4B6A20",
    "FIGUEROLLES": "#7F5BF7",
    "FIGUIERE": "#1A9807",
    "FONT COUVERTE": "#4E062A",
    "FONT DE SALOME": "#995BC6",
    "FONT DU NOYER": "#33BD86",
    "FONT MAJOUR": "#06979A",
    "FONT TROUVE": "#EC4B3E",
    "FONTAINE CHENES": "#8E6994",
    "FONTAINE ROMAINE": "#692D3A",
    "FONTCAUDE": "#B2A66C",
    "FRANCOIS DEZEUZE": "#D4CDB1",
    "FRAYSSINET": "#212CE0",
    "FREDERIC BAZILLE": "#6C8A42",
    "FREDERIC MISTRAL": "#C60947",
    "FREDERIC PEYSON": "#0ADC15",
    "G. JANVIER": "#49F3AA",
    "G. MARCONI": "#7BC65D",
    "GALERA": "#006717",
    "GAMBETTA": "#8D477B",
    "GARCIA LORCA": "#E64B07",
    "GARDIOLES": "#62B418",
    "GARE": "#9FD408",
    "GARE BAILLARGUES": "#F7DA6B",
    "GARE PT DE SETE": "#D144A4",
    "GARE SAINT-ROCH": "#A59671",
    "GARE ST-ROCH T1": "#4F7EE4",
    "GARE ST-ROCH T3": "#BF09D0",
    "GARE SUD FRANCE": "#83A2FF",
    "GARRIGUETTES": "#51BE63",
    "GASCOGNE": "#0973E3",
    "GASPARD MONGE": "#EA7040",
    "GASTON BAISSETTE": "#48D7C0",
    "GASTON DEFFERRE": "#C3FEF9",
    "GASTON PLANTE": "#E1B361",
    "GEMEAUX": "#4B3F7C",
    "GENETS": "#0858AF",
    "GEORGES BRASSENS": "#77C074",
    "GEORGES DANTON": "#F8FBE4",
    "GEORGES POMPIDOU": "#976A63",
    "GLYCINES": "#3CA41D",
    "GOELANDS": "#63A302",
    "GOUARA": "#D90AA2",
    "GRABELS CENTRE": "#302345",
    "GRAMENET": "#0998B0",
    "GREZES": "#AF2DE4",
    "GUSTAVE COURBET": "#BAADF2",
    "GUSTAVE EIFFEL": "#15DECF",
    "HALLES PAILLADE": "#6720FD",
    "HAUTS DE CLAVOUX": "#89C3C2",
    "HAUTS DE MASSANE": "#B5C968",
    "HAUTS FONTCAUDE": "#B7E90F",
    "HELIOS": "#CB559D",
    "HENRI DUNANT": "#BBA5B3",
    "HENRI MARES": "#48011F",
    "HENRI RENE": "#553F4D",
    "HIPPOLYTE RECH": "#0BE467",
    "HOP. LAPEYRONIE": "#4B60CB",
    "HORTUS": "#381B2D",
    "HOT. DEPARTEMENT": "#A33E89",
    "HOTEL DE VILLE": "#21831E",
    "HTS LA LIRONDE": "#ABBE16",
    "IDATE": "#EA821A",
    "INDUSTRIE": "#EC66EA",
    "IRIS": "#1BC09F",
    "IRISSOU": "#CFC730",
    "J-M. CASTET": "#3F8AC5",
    "JACOU": "#C9C50F",
    "JACQUES PREVERT": "#64B45C",
    "JARDINS D'HELIOS": "#132F53",
    "JASSES": "#E9BF61",
    "JEAN BART": "#C305E9",
    "JEAN CALVIN": "#195581",
    "JEAN FERRAT": "#42CE8E",
    "JEAN JAURES": "#4FB9D9",
    "JEAN JOUBERT": "#525B9E",
    "JEAN MIRAILHET": "#2A19DC",
    "JEAN MOULIN": "#040950",
    "JEAN VACHET": "#623580",
    "JEAN VILAR": "#418569",
    "JEU DE BALLON": "#4BC722",
    "JULES GUESDE": "#98C8D0",
    "JUVIGNAC": "#A3D92F",
    "L'ILE BLEUE": "#D256CA",
    "LA BERGERIE": "#A64475",
    "LA BORNIERE": "#5D9A82",
    "LA CAPOULIERE": "#47897D",
    "LA CONDAMINE": "#B187B5",
    "LA CROISEE": "#299EDA",
    "LA CROIX": "#4EEA32",
    "LA DRAYE": "#C5EF86",
    "LA FERMAUDE": "#49ACDA",
    "LA FONTAINE": "#0CDB32",
    "LA GAILLARDE": "#518107",
    "LA GALINE": "#124759",
    "LA GRAND FONT": "#8031F8",
    "LA LAUZE": "#174566",
    "LA LIRONDE": "#0A31D8",
    "LA MARTELLE": "#580C40",
    "LA PILE": "#7DE4D7",
    "LA PLAINE": "#CF5391",
    "LA RAUZE": "#5DB913",
    "LA REGNAGUE": "#7E5D17",
    "LA SOURCE": "#200B95",
    "LA VALSIERE": "#B745C7",
    "LA VIALETTE": "#887ACF",
    "LA VIERGE": "#048706",
    "LAGOYA": "#34133E",
    "LAS CAZES": "#555752",
    "LAS REBES": "#542101",
    "LAS SORBES": "#73E225",
    "LATTES CENTRE": "#B1D622",
    "LAVALETTE": "#17E593",
    "LAVANDIN": "#B403C7",
    "LE GRAND M": "#26C767",
    "LE GRES": "#71BFC9",
    "LE HAMEAU": "#FFE484",
    "LE LABOURNAS": "#999F3C",
    "LE LEVANT": "#0F51B2",
    "LE MARTINET": "#95DD92",
    "LE PONTIL": "#318805",
    "LE PRADAS": "#528599",
    "LE RESERVOIR": "#BA4D96",
    "LE RIO": "#704BA4",
    "LE SAINT-GEORGES": "#2D466F",
    "LE TEMPLE": "#D0CC33",
    "LE TERRAL": "#9411ED",
    "LEENHARDT": "#19FA44",
    "LEMASSON": "#A4DC87",
    "LEON CAZAL": "#17CEBD",
    "LEON CORDES": "#FECA06",
    "LEPIC": "#9390D5",
    "LES ALICANTES": "#B23319",
    "LES ARCEAUX": "#47FF2F",
    "LES AUBES": "#2181F2",
    "LES BARONNES": "#F31A4B",
    "LES BOUISSES": "#DC8ADA",
    "LES CLAUZES": "#CB8D72",
    "LES CLAVOUX": "#80CEC6",
    "LES COUSSES": "#7DCE50",
    "LES ECOLES": "#AAD7B4",
    "LES GRENACHES": "#A0E074",
    "LES GRISETTES": "#AC3EDD",
    "LES JARDINS": "#94B4A0",
    "LES MOURES": "#8D52E0",
    "LES ROSES": "#9057EC",
    "LES SERRES": "#741040",
    "LES TERRASSES": "#A4A162",
    "LES THERMES": "#7F695C",
    "LOT. VIOLETTES": "#6A50FE",
    "LOU PAILLAS": "#E59288",
    "LOU PRADET": "#44DC68",
    "LOUIS BLANC": "#4FCA2E",
    "LOUIS LEPINE": "#9C4A48",
    "LOUIS RAVAZ": "#CC85F2",
    "LOUVAIN": "#56B546",
    "LUCIEN LAMBERT": "#3DA4D2",
    "LUCULLUS": "#201AC2",
    "LUMINAIRE": "#48A58D",
    "LYCEE CLEMENCEAU": "#415896",
    "LYCEE DE VINCI": "#4C16C3",
    "LYCEE F. BAZILLE": "#013084",
    "LYCEE J. FERRY": "#94DDA1",
    "LYCEE J. MONNET": "#0FF88E",
    "M. CHEVALIER": "#D03C4E",
    "MAIL DES ABBES": "#7CFCF7",
    "MAIRIE": "#7F44BB",
    "MAISON D'ARRET": "#B8AECC",
    "MAISON RETRAITE": "#C15F6E",
    "MAISON SPORTS": "#1DEA42",
    "MAJOR FLANDRE": "#150661",
    "MALBOSC": "#4B939B",
    "MANDARINE": "#4A62B5",
    "MANSE": "#13E066",
    "MARCEL DASSAULT": "#324775",
    "MARCHE GARE": "#40CF29",
    "MARECHAL LECLERC": "#C1DDA3",
    "MARIE CAIZERGUES": "#07D4E3",
    "MARIE CURIE": "#1B5451",
    "MARIE DURAND": "#8F0BD2",
    "MARJORIES": "#62F4F5",
    "MARQUEROSE": "#A3CF81",
    "MARTELLIERE": "#7E561B",
    "MAS ARGELLIERS": "#2BA1C7",
    "MAS DE BAGNERES": "#604C93",
    "MAS DE BELLEVUE": "#E0B080",
    "MAS DE GARENC": "#EECAB5",
    "MAS DE GUE": "#69EF41",
    "MAS DE LEPOT": "#8814AF",
    "MAS DE MAGRET": "#9D5134",
    "MAS DE MERLE": "#D2663B",
    "MAS DE ROCHET": "#E0FB75",
    "MAS DREVON": "#80328B",
    "MAS LA FAROUCH": "#A1EBA2",
    "MAS SAINT-PIERRE": "#88CD03",
    "MAUMARIN": "#1E22D9",
    "MAURICE CHAUVET": "#A66EBD",
    "MEDIATHEQUE": "#8DAD93",
    "MEDIATHEQUE GARE": "#C0DF8D",
    "MEDITERRANEE": "#3EA496",
    "MEJEAN": "#40F57A",
    "MELANIE": "#4021B6",
    "MENDE AGROPOLIS": "#F00380",
    "MICOCOULIER": "#489DC9",
    "MILLENAIRE": "#266092",
    "MONDIAL 98": "#406338",
    "MONNAIE": "#D44170",
    "MONSIEUR TESTE": "#BE4D2A",
    "MONTERONI ARBIA": "#1620E3",
    "MONTMAUR": "#58238C",
    "MONTOUZERES": "#FFF60B",
    "MONTPELLIERET": "#57879E",
    "MOSSON": "#BDC03D",
    "MOSSON T1": "#2DE52D",
    "MOSSON T3": "#2AA92B",
    "MOULARES": "#910224",
    "MOULIERES": "#E92A4D",
    "MOULIN BOUDET": "#F0E57A",
    "MOULIN GASCONNET": "#9D4319",
    "MOULIN NEUF": "#02E60A",
    "MURIERS": "#37FB42",
    "MYOSOTIS": "#AE375F",
    "MYRTES": "#990F02",
    "NAZARETH": "#6A1835",
    "ND DE SABLASSOU": "#9CE8BA",
    "NOUAU": "#388C97",
    "NOUVEAU ST-ROCH": "#E94AF3",
    "OASIS PALAVAS.": "#E68634",
    "OBSERVATOIRE": "#A61423",
    "OCCITANIE": "#797A38",
    "ODYSSEUM": "#3FF1CE",
    "OLIVETTE": "#B988DA",
    "OLIVIERS": "#1CAD79",
    "OLIVIERS BOHEME": "#31D9ED",
    "OLYMPE": "#750947",
    "OLYMPE DE GOUGES": "#D7C2EC",
    "ORMEAUX": "#FB2AD0",
    "OXFORD": "#D4C567",
    "P. DE COUBERTIN": "#D6A6FC",
    "PABLO PICASSO": "#81F614",
    "PAIX": "#F69AB1",
    "PALADILHE": "#24F67B",
    "PALISSADE": "#BAE05A",
    "PAQUERETTES": "#B732AC",
    "PARADIS": "#522C2E",
    "PARC EXPO": "#B5850C",
    "PARC GOUNEAUD": "#76FA54",
    "PARC LIRONDE": "#4C3205",
    "PARC MALBOSC": "#636199",
    "PARC VENDARGUES": "#A5CF3A",
    "PARIS": "#7E1451",
    "PAS DU LOUP": "#5663B5",
    "PASTOURELLES": "#BDEA96",
    "PAUL CEZANNE": "#C45F35",
    "PAUL FAJON": "#06B402",
    "PAUL GAUGUIN": "#FF2D5B",
    "PAUL PAINLEVE": "#E0D51B",
    "PAUL PARGUEL": "#D7C8BF",
    "PELERINE": "#9247A7",
    "PERGOLA": "#B84F10",
    "PEROLS CENTRE": "#A66AB3",
    "PEROLS ETANG OR": "#342C67",
    "PETIT BARD": "#F2A8F7",
    "PEYROU": "#5B7593",
    "PHILIPPE LAMOUR": "#34F4B9",
    "PHILIPPIDES": "#DC32A5",
    "PIC SAINT-LOUP": "#11BF60",
    "PICADOUS": "#8A9DBA",
    "PICHEYROU": "#DF5D51",
    "PIERRE CARDENAL": "#535277",
    "PIERRE D'ADHEMAR": "#37A92E",
    "PIERRE DOUMERGUE": "#23DF66",
    "PIERRE LAROQUE": "#CA95C9",
    "PIERRE ROUGE": "#60627F",
    "PIERRE VIALA": "#ED678B",
    "PIGEONNIERS": "#0F9521",
    "PILORY": "#7477DB",
    "PILOU": "#B0EA06",
    "PINEDE": "#88C1D2",
    "PINVILLE": "#949D00",
    "PIOCH BOUTONNET": "#C2B4B1",
    "PIOCH PELAT": "",
    "PISCINE HERACLES": "#E91FFD",
    "PL. DE L'EUROPE": "#71E255",
    "PLACE ALBERT 1ER": "#EAFD00",
    "PLACE CARNOT": "#655DD7",
    "PLACE D'ITALIE": "#6DE666",
    "PLACE DE FRANCE": "#D07FE8",
    "PLACE DU 8 MAI": "#61EE09",
    "PLACE LA LIBERTE": "#8ECACA",
    "PLAN 4 SEIGNEURS": "#13E038",
    "PLAN CABANES": "#805161",
    "PLANKSTADT": "#575F75",
    "PLEIADE": "#BAB14B",
    "POINTES": "#79131C",
    "POMPIGNANE": "#3F158C",
    "POMPIGNANE- LACS": "#4D06B4",
    "PONT JUVENAL": "#252050",
    "PONT VILLENEUVE": "#E25F20",
    "PORT MARIANNE": "#BFF681",
    "PORTO": "#E94F02",
    "POSTE": "#5F7119",
    "POUS DE LA SERS": "#599C30",
    "PRIMEVERES": "#CD252C",
    "PUCCINI": "#A58AB6",
    "PUECH VILLA": "#28A890",
    "QUATRE CHEMINS": "#4EBC47",
    "RAIMBAUD ORANGE": "#90B540",
    "RAPATEL": "#9832B6",
    "RAZETEURS": "#DAC18C",
    "RECANTOU": "#1D1330",
    "RENAUDEL": "#B21011",
    "RENE BOUGNOL": "#212BA3",
    "RESTANQUE": "#A27322",
    "RIEGES": "#2921C1",
    "RIVES DU LEZ T1": "#E5B342",
    "RIVES DU LEZ T3": "#1A96B8",
    "RIVIERE": "#DF94E6",
    "ROCAILLES": "#9CED91",
    "RONCERAY": "#FC3A44",
    "RONDELET": "#DCEAF2",
    "ROQUETURIERE": "#BE9771",
    "ROUDERES": "#F785AC",
    "ROUGET DE LISLE": "#4E8BB3",
    "ROUTE DE GANGES": "#4046B9",
    "ROUTE DE NIMES": "#D048B5",
    "ROUTE ST-GEORGES": "#F513E0",
    "ROUVIERE LONGUE": "#E794AF",
    "SABINES": "#108E2F",
    "SAINT-CLEMENT": "#CD9943",
    "SAINT-CLEOPHAS": "#0E95D7",
    "SAINT-DENIS": "#780AA5",
    "SAINT-DENIS T3": "#BBE217",
    "SAINT-ELOI": "#7E3E70",
    "SAINT-ESTEVE": "#7F7C48",
    "SAINT-GABRIEL": "#AC3BF8",
    "SAINT-GUILHEM": "#05D7AC",
    "SAINT-HUBERY": "#D60A4E",
    "SAINT-JEAN": "#7BCD10",
    "SAINT-LAZARE": "#A18238",
    "SAINT-MARTIN": "#CC3A68",
    "SAINT-MICHEL": "#21981B",
    "SAINT-PAUL": "#5A3FFA",
    "SAINT-PIERRE": "#778C14",
    "SAINT-PRIEST": "#8A6C38",
    "SAINT-ROCH": "#4278AC",
    "SAINTE-CATHERINE": "#A5C2D6",
    "SAINTE-COLOMBE": "#6258CB",
    "SAINTE-GENEVIEVE": "#18F748",
    "SAINTE-ODILE": "#9F2648",
    "SAINTE-THERESE": "#735299",
    "SALAISON": "#64BE4F",
    "SAMUEL MORSE": "#03C23D",
    "SAPORTA": "#223A2F",
    "SATURNE": "#564A0B",
    "SAUVAGINE": "#E157FA",
    "SEMARD": "#905D4E",
    "SIENNE": "#5C6655",
    "SIGALIES": "#2F516B",
    "SOLEIADO": "#DDA756",
    "SOPHIE GERMAIN": "#A4E691",
    "SOPHORAS": "#266CE6",
    "SOURCE": "#CE1D23",
    "SQUARE TRIBES": "#C4C620",
    "ST-JEAN CENTRE": "#8B75CD",
    "ST-JEAN LE SEC": "#3C0984",
    "ST-M. VIGNOGOUL": "#C23978",
    "STADE": "#72AF18",
    "STADE MOSSON": "#C22D41",
    "SUBSTANTION": "#C63575",
    "TADORNES": "#E8C562",
    "TAMBOURIN": "#A0B8B2",
    "TEMPLE": "#BD555C",
    "TERRE NEUVE": "#4FD0ED",
    "TERRES DU SUD": "#BF14D5",
    "TONNELLES": "#701F91",
    "TOURNESOL": "#E0DE9E",
    "TOURNEZY": "#F5B9F8",
    "TOURTERELLES": "#7BBBAA",
    "TREILLE": "#65D009",
    "UNIVERSITES": "#F0FCE8",
    "UPPSALA": "#C2AE99",
    "VAL D'AURELLE": "#2958B9",
    "VAL DE GARRIGUES": "#1FF657",
    "VALAT DE FOSSE": "#CAA9DF",
    "VAUGUIERES": "#105628",
    "VEGA": "#5DCE84",
    "VERSANT": "#9AA6B1",
    "VERT-BOIS": "#F1D206",
    "VIA DOMITIA": "#7CF1F0",
    "VICTOIRE 2": "#EA7A4D",
    "VIEILLE POSTE": "#5E169D",
    "VIGNES VIERGES": "#A5EB7E",
    "VIL. D'ANGOULEME": "#06E6D4",
    "VILLAGE": "#C081CA",
    "VINCENT AURIOL": "#8447B9",
    "VIVALDI": "#3B3814",
    "VOIE DOMITIENNE": "#33F6F2",
    "VOLTA FIZEAU": "#2FFB94",
    "VOLTAIRE": "#69F808",
    "W. CHURCHILL": "#7B1E0F",
    "WALHALLA": "#0BC4BB",
    "YVES DU MANOIR": "#155EAE",
    "ZI BIGOS": "#95859F",
    "ZI MARBRERIE": "#84A795",
    "ZONE ARTISANALE": "#64481F",
    "ZOO": "#F7B677"
}

const terminus_colors = {
    "AIGUELONGUE": "#CF9E7D",
    "CASTRIES": "#F48101",
    "CATALPAS": "#03D834",
    "CELLENEUVE": "#831867",
    "CH. DE GAULLE": "#56685C",
    "CHARLES DE GAULLE": "#9D3C55",
    "CITE DE L'ARME": "#58F6DF",
    "CLINIQUE DU PARC": "#CF61CD",
    "COUBERTIN": "#AD5BE6",
    "COURNONSEC": "#62A386",
    "COURPOUYRAN": "#4726CF",
    "EUROMEDECINE": "#1C7A48",
    "FABREGUES": "#3B23C0",
    "FERMAUDE M. MAGRET": "#D838F4",
    "G.LORCA VIA M.ARR": "#63F6EC",
    "G.LORCA VIA P.VILL": "#03ADFA",
    "GARCIA LORCA": "#48D11C",
    "GARE SAINT-ROCH": "#1E48DD",
    "GARE SUD DE FRANCE": "#9F86BA",
    "GRABELS LE PRADAS": "#9DF7CE",
    "H. DEPARTEMENT": "#967254",
    "JACOU": "#E56C21",
    "JACOU COLLEGE": "#CA7557",
    "JEAN JAURES": "#AB531F",
    "JUVIGNAC": "#B4D3BE",
    "LA FERMAUDE": "#A87022",
    "LA MARTELLE": "#69C72C",
    "LA VALSIERE": "#A06ED8",
    "LATTES CENTRE": "#FA31F5",
    "LAVANDIN": "#2F01CB",
    "LE MARTINET": "#5BEB48",
    "LES BOUISSES": "#FD2836",
    "MAUMARIN": "#E8F94E",
    "MONTFERRIER CIRAD": "#47C1F8",
    "MOSSON": "#EC75B5",
    "MOULIN NEUF": "#B80112",
    "NAVETTE A": "#293CBC",
    "NAVETTE B": "#FFA0D8",
    "ND DE SABLASSOU": "#DCB15D",
    "OCCITANIE": "#30FEA3",
    "ODYSSEUM": "#7B78B8",
    "PAS DU LOUP": "#9473CA",
    "PEROLS ETANG": "#F5B436",
    "PIGNAN - BORNIERE": "#65C18A",
    "PILOU": "#2F1A29",
    "PL. DE L'EUROPE": "#26E569",
    "PLACE DE FRANCE": "#86B1F2",
    "PORT MARIANNE": "#F146C8",
    "RONDELET": "#E9B233",
    "ROUVIERE LONGUE": "#E8F0A8",
    "SABINES": "#E0D06E",
    "SABLASSOU VIA ZI": "#D4FB37",
    "SAINT-BRES": "#997A75",
    "SAINT-DENIS": "#B68703",
    "SAINT-GEORGES": "#D74CF4",
    "ST JEAN DE VEDAS": "#6592A5",
    "ST-BRES VIA ZI": "#4AC66B",
    "ST-JEAN DE VEDAS": "#96F332",
    "ST-JEAN LE SEC": "#9E4DE2",
    "ST-JEAN SEC MAGRET": "#FC845C",
    "ST-JEAN VIA M.ARR": "#3DB7D1",
    "ST-JEAN VIA P.VILL": "#D299EC",
    "TOURNEZY": "#BAAB5F",
    "UNIVERSITES": "#144CF2",
    "VIA DOMITIA": "#F2FA95"
}

export default {
    directions_colors,
    terminus_colors
}