<template>
  <div class="main">
    <div class="router overflow">
      <router-view />
    </div>

    <div id="nav-bar">
      <router-link class="item-nav-bar" to="/"> 
         <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-aerial-lift" width="52" height="52" viewBox="0 0 24 24" stroke-width="1.5" :stroke="routeIs('Tam') ? '#00bfd8' : '#545454'" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M4 5l16 -2m-8 1v10m-5.106 -6h10.306c2.45 3 2.45 9 -.2 12h-10.106c-2.544 -3 -2.544 -9 0 -12zm-1.894 6h14" />
        </svg>
      </router-link>

      <router-link class="item-nav-bar"  to="/sncf"> 
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-train" width="52" height="52" viewBox="0 0 24 24" stroke-width="1.5" :stroke="routeIs('SNCF') ? '#00bfd8' : '#545454'" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M21 13c0 -3.87 -3.37 -7 -10 -7h-8" />
        <path d="M3 15h16a2 2 0 0 0 2 -2" />
        <path d="M3 6v5h17.5" />
        <line x1="3" y1="10" x2="3" y2="14" />
        <line x1="8" y1="11" x2="8" y2="6" />
        <line x1="13" y1="11" x2="13" y2="6.5" />
        <line x1="3" y1="19" x2="21" y2="19" />
      </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    routeIs: function(routeName) 
    {
      if(this.$route.name === routeName) return true;
      else return false;
    }
  }
};

</script>

<style>
html,
body {
  padding: 0px;
  margin: 0px;
  background-color: #353942;
}

#app {
  font-family: "Ubuntu", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  background-color: #353942;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.main {
  text-align: left;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: space-around;
  align-items: center;

  background-color: #303236;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  overflow: auto;
}

@media screen and (min-width: 450px) {
  .main {
    width: 430px;
    height: 800px;
    border-radius: 5px;
  }
}

#nav-bar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  
  position: sticky;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 70px;

  -webkit-box-shadow: 0px -1px 13px 4px rgba(0,0,0,0.25); 
  box-shadow: 0px -1px 13px 4px rgba(0,0,0,0.25);
  
  background-color: #303236;
}

.item-nav-bar {
  flex: 0 0 auto;
  border-radius: 10px;
}

.router
{
  position: relative;
  min-height: 90%;
}

</style>
