<template>
  <h4>Favoris</h4>
  <p id="time">{{ timestamp }}</p>
  <p id="favoris-info" v-if="Object.keys(likes).length <= 0">
    Vous n'avez pas de favoris
  </p>

  <template v-for="(ligne, nb_ligne) in likes" :key="ligne">
    <!-- Item list -->
    <div class="card" v-for="arret in ligne" :key="arret" style="color: white">
      <p style="font-size: 24px; margin-bottom: 10px">
        {{ nb_ligne }} - {{ arret }}
      </p>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-heart fav-icon"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#9e9e9e"
        fill="red"
        stroke-linecap="round"
        stroke-linejoin="round"
        v-on:click="unlike(nb_ligne, arret)"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"
        />
      </svg>

      <div v-if="favoris[`${nb_ligne}-${arret}`]">
        <p v-if="favoris[`${nb_ligne}-${arret}`][0] == 'No data'">
          Pas de données actuellement
        </p>
        <p
          v-else
          v-for="time in favoris[`${nb_ligne}-${arret}`][0].times"
          :key="time"
          style="font-size: 20px"
        >
          <span
            >{{ time.departure_time
            }}<span v-if="time.is_theorical" style="color: pink">*</span></span
          >
          -
          <span
            :style="{
              color: const_colors.terminus_colors[time.trip_headsign],
            }"
            >{{ time.trip_headsign }}</span
          >
        </p>
      </div>
      <div v-else style="text-align: center;">
        <!--<iframe src='/loading/index.html' frameborder='0' width='100%' height='100px'></iframe>-->
        <img src="/loading/loading.gif" alt="Animation de chargement" width="100" height="100" />
      </div>
    </div>
  </template>

  <h4>Choisissez une ligne</h4>
  <div v-if="lignes_list.length == 0" style="text-align: center;">
        <!--<iframe src='/loading/index.html' frameborder='0' width='100%' height='100px'></iframe>-->
        <img src="/loading/loading.gif" alt="Animation de chargement" width="100" height="100" />
  </div>
  <div class="container-lignes">
    <!-- Item list -->
    <router-link
      tag="div"
      class="card container-lignes-list item-lignes"
      v-for="ligne in lignes_list"
      :key="ligne"
      :to="`/arrets/${ligne}`"
    >
      <p>{{ ligne }}</p>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";
import const_colors from "./constants_colors.js";

export default {
  name: "TAM",
  data() {
    return {
      lignes_list: [],
      likes: {},
      favoris: {},
      const_colors,
      timestamp: ""
    };
  },
  created() {
    if (localStorage.likes !== undefined && localStorage.likes !== "")
      this.likes = JSON.parse(localStorage.likes);

    for (let ligne in this.likes) {
      for (let arret in this.likes[ligne]) {
        this.getTime(ligne, this.likes[ligne][arret]);
      }
    }

    this.getNow();
    setInterval(this.getNow, 1000);
  },
  methods: {
    unlike: function (ligne, arret_name) {
      const index = this.likes[ligne].indexOf(arret_name);
      if (index > -1) {
        this.likes[ligne].splice(index, 1);
      }
      if (this.likes[ligne].length == 0) delete this.likes[ligne];
      localStorage.likes = JSON.stringify(this.likes);
    },
    getTime: function (ligne, arret) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/tam/times/${ligne}/${arret}`)
        .then((response) => {
          if (response.data.length > 0)
            this.favoris[ligne + "-" + arret] = response.data;
          else this.favoris[ligne + "-" + arret] = ["No data"];
        })
        .catch((error) => {
          alert("Une erreur est survenue avec vos favoris: " + error.message);
        });
    },
    getNow: function () {
      const today = new Date();
      const time =
        with_leading_zeros(today.getHours()) +
        ":" +
        with_leading_zeros(today.getMinutes()) +
        ":" +
        with_leading_zeros(today.getSeconds());
      this.timestamp = time;
    },
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/tam/liste/lignes`)
      .then((response) => {
        this.lignes_list = response.data;
        //console.log(response)
      })
      .catch((error) => {
        alert(
          "Une erreur est survenue avec la récupération des lignes: " +
            error.message
        );
      });
  },
};

function with_leading_zeros(dt) {
  return (dt < 10 ? "0" : "") + dt;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  position: relative;
  text-align: left;
  padding: 5px 10px;
  margin: 7px 5px;
  background-color: #494b51;
  border-radius: 7px;
  color: cyan;
  text-decoration: none;
}

p {
  margin: 0px;
}

#favoris-info {
  text-align: center;
  font-size: 22px;
}

h4 {
  margin: 10px;
}

hr {
  border-color: gray;
  width: 80%;
}

.container-lignes-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: start;
}

.item-lignes-list {
  flex: 0 0 auto;
}

.container-lignes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: start;
  padding: 5px;
}
.item-lignes {
  flex: 0 0 auto;
  margin: 5px;
}

.container-arrets-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: start;
}

.item-arrets-list {
  flex: 0 0 auto;
}

.container-arrets {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: start;
  padding: 5px;
}
.item-arrets {
  flex: 0 0 auto;
  margin: 5px;
}
.time-p {
  color: white;
}

.fav-icon {
  top: 5px;
  right: 7px;
  position: absolute;
}

#time {
  position: absolute;
  top: 0px;
  right: 10px;
}

.title-dir {
  color: cyan;
}
</style>
