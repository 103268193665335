import { createWebHistory, createRouter } from "vue-router";
import tam from "@/components/tam.vue";
import tal_arret_lignes from "@/components/arrets.vue";
import sncf from "@/components/sncf.vue";

const routes = [
  {
    path: "/",
    name: "Tam",
    component: tam,
  },
  {
    path: "/arrets/:ligne",
    name: "Arrets",
    component: tal_arret_lignes,
  },
  {
    path: "/sncf",
    name: "SNCF",
    component: sncf,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;