<template>
  <h4>Arrets de la ligne {{ ligne_nb }}</h4>
  <p id="time">{{ timestamp }}</p>

  <!-- Item list -->
  <div v-if="arrets_list.length < 1" style="text-align: center;">
    <img src="/loading/loading.gif" alt="Animation de chargement" width="100" height="100" />
  </div>

  <div class="card" v-for="arret in arrets_list" :key="arret._id.stop_name">
    <p style="font-size: 24px; margin-bottom: 10px">
      {{ arret._id.stop_name }}
    </p>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-heart fav-icon"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#9e9e9e"
      :fill="likes[ligne_nb].includes(arret._id.stop_name) ? 'red' : 'none'"
      stroke-linecap="round"
      stroke-linejoin="round"
      v-on:click="like(arret._id.stop_name)"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"
      />
    </svg>

    <div>
      <p v-for="time in arret.times" :key="time">
        <span
          >{{ time.departure_time
          }}<span v-if="time.is_theorical" style="color: pink">*</span></span
        >
        -
        <span
          :style="{ color: const_colors.terminus_colors[time.trip_headsign] }"
          >{{ time.trip_headsign }}</span
        >
      </p>
    </div>
  </div>
  <p style="text-align: center">
    <span style="color: pink">*</span> heure théorique
  </p>
  <router-link to="/" style="color: #00ff62">Retour</router-link>
</template>

<script>
import axios from "axios";
import const_colors from "./constants_colors.js";

export default {
  name: "TAM",
  data() {
    return {
      arrets_list: [],
      ligne_nb: this.$route.params.ligne,
      const_colors,
      timestamp: "",
      likes: {},
    };
  },
  created() {
    this.getNow();
    setInterval(this.getNow, 1000);

    this.reloadLikes();
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/tam/times/${this.ligne_nb}`)
      .then((response) => {
        this.arrets_list = response.data;
      })
      .catch((error) => {
        alert("Une erreur est survenue: " + error.message);
      });
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const time =
        with_leading_zeros(today.getHours()) +
        ":" +
        with_leading_zeros(today.getMinutes()) +
        ":" +
        with_leading_zeros(today.getSeconds());
      this.timestamp = time;
    },
    like: function (arret_name) {
      if (this.likes[this.ligne_nb] === undefined)
        this.likes[this.ligne_nb] = [];
      if (!this.likes[this.ligne_nb].includes(arret_name)) {
        this.likes[this.ligne_nb].push(arret_name);
        localStorage.likes = JSON.stringify(this.likes);
      } else {
        const index = this.likes[this.ligne_nb].indexOf(arret_name);
        if (index > -1) {
          this.likes[this.ligne_nb].splice(index, 1);
        }
        if (this.likes[this.ligne_nb].length == 0)
          delete this.likes[this.ligne_nb];
        localStorage.likes = JSON.stringify(this.likes);
        this.likes[this.ligne_nb] = [];
        this.reloadLikes();
      }
    },
    reloadLikes: function () {
      if (localStorage.likes !== undefined && localStorage.likes !== "")
        this.likes = JSON.parse(localStorage.likes);
      if (this.likes[this.ligne_nb] === undefined) {
        this.likes[this.ligne_nb] = [];
      }
    },
  },
};

function with_leading_zeros(dt) {
  return (dt < 10 ? "0" : "") + dt;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  text-align: left;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: space-around;
  align-items: center;

  background-color: #303236;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  padding: 5px;

  position: relative;
  overflow-y: auto;
}

@media screen and (min-width: 450px) {
  .main {
    width: 430px;
    height: 800px;
    border-radius: 5px;
  }
}

.card {
  position: relative;
  text-align: left;
  padding: 5px 10px;
  margin: 7px 5px;
  background-color: #494b51;
  border-radius: 7px;
}

#favoris-info {
  text-align: center;
  font-size: 22px;
}

h4 {
  margin: 10px;
}

.container-arrets-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: start;
}

.item-arrets-list {
  flex: 0 0 auto;
}

.container-arrets {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: start;
  padding: 5px;
}
.item-arrets {
  flex: 0 0 auto;
  margin: 5px;
}

.title-dir {
  color: cyan;
}

p {
  margin: 0px;
  font-size: 20px;
}

.time-p {
  color: white;
}

.fav-icon {
  top: 5px;
  right: 7px;
  position: absolute;
}

#time {
  position: absolute;
  top: 5px;
  right: 10px;
}
</style>
