<template>
  <p id="time">{{ timestamp }}</p>

  <form class="card" action="javascript:void(0);">
    <div v-if="!loading">
      <label for="trainNumber">Numéro de train</label>
      <br />
      <input type="number" id="trainNumber" name="trainNumber" required />

      <label for="trainDate">Date</label>
      <br />
      <input
        type="date"
        id="trainDate"
        name="trainDate"
        required
        value="2002-02-20"
      />

      <input
        type="submit"
        value="Ajouter"
        class="sendButton"
        v-on:click="addTrain"
      />
    </div>
    <div v-else style="text-align: center;">
      <!--<iframe src='/loading/index.html' frameborder='0' width='100%' height='100px'></iframe>-->
      <img src="/loading/loading.gif" alt="Animation de chargement" width="100" height="100" />
    </div>

  </form>



  <div class="card" v-for="(train, index) in trains" :key="train">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-heart-broken" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fd0061" fill="none" stroke-linecap="round" stroke-linejoin="round" v-on:click="removeTrain(index)">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
      <path d="M12 7l-2 4l4 3l-2 4v3" />
    </svg>

    <svg v-if="!loading" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="36" height="36" viewBox="0 0 24 24" stroke-width="2" stroke="#00abfb" fill="none" stroke-linecap="round" stroke-linejoin="round" v-on:click="refreshTrain(index)">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
      <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
    </svg>

    <h4>{{ train.train }} - {{ train.date }}</h4>
    <p>{{ train.nb_arrets }}</p>
    <p>{{ train.infos }}</p>
    <button class="voirButton" v-on:click="openModel(index)">Voir 
      <svg v-if="train.infos_sup || train.retard" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle" width="28" height="28" viewBox="0 0 24 24" stroke-width="2.5" stroke="#fd0061" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M12 9v2m0 4v.01" />
        <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
      </svg>
    </button>
  </div>

  <div id="myModal" class="modal" v-if="modalId > -1" v-on:click="modalId = -1">
    <div class="modal-content">

      <span class="close" v-on:click="modalId = -1">&times;</span>

      <div class="card" style="color: #ff6363; white-space: pre-line; font-size: 20px; margin-top: 30px;" v-if="trains[modalId].infos_sup || trains[modalId].retard">
        <p>{{ trains[modalId].retard }}</p>
        <p>{{ trains[modalId].infos_sup }}</p>
      </div>

      <div v-for="arrets in trains[modalId].arrets" :key="arrets" class="arrets">
        <p>
          <span :style="arrets.timePast ? 'color: #ff6363;' : 'color: #949699;'">{{ arrets.heure }}</span> 
          <span v-if="arrets.timePast" style="color: #949699; font-size: 20px; text-decoration:line-through;">  ({{ arrets.timePast }})</span>
           {{ arrets.arret }}
        </p>
        <p v-if="arrets.tempsArret != null" class="arretTime">{{ arrets.tempsArret }}</p>
        <p v-if="arrets.info_retard" style="white-space: pre-line; font-size: 18px; color: #949699;">{{ arrets.info_retard }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

Date.prototype.toDateInputValue = function () {
  var local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

function setToday() {
  let d = new Date().toDateInputValue();
  document.getElementById("trainDate").value = d;
}

export default {
  name: "SNCF",
  data() {
    return {
      timestamp: "",
      trains: [],
      loading: false,
      modalId: -1
    };
  },
  created() {
    this.getNow();
    setInterval(this.getNow, 1000);
    this.reloadTrains();
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const time =
        with_leading_zeros(today.getHours()) +
        ":" +
        with_leading_zeros(today.getMinutes()) +
        ":" +
        with_leading_zeros(today.getSeconds());
      this.timestamp = time;
    },
    addTrain: function () {
      this.loading = true;
      const trainNumber = document.getElementById("trainNumber").value;
      if(trainNumber === "")
      {
        this.loading = false;
         return;
      }
      const date = new Date(document.getElementById("trainDate").value)
      const trainDate = `${date.getUTCDate()}/${date.getUTCMonth()+1}/${date.getUTCFullYear()}` 
      axios
        .get(`${process.env.VUE_APP_API_URL}/sncf/infos/${trainNumber}/${trainDate}`)
        .then((response) => {
          this.trains.push(response.data)
          localStorage.trains = JSON.stringify(this.trains)
          this.reloadTrains()
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          alert("Une erreur est survenue en ajoutant votre train: " + error.message);
        });
    },
    reloadTrains: function () {
      if (localStorage.trains !== undefined && localStorage.trains !== "")
        this.trains = JSON.parse(localStorage.trains);
    },
    removeTrain: function(index) {
      if (index > -1) {
        this.trains.splice(index, 1);
      }
      localStorage.trains = JSON.stringify(this.trains)
      this.reloadTrains()
    },
    openModel: function(index) {
      this.modalId = index;
    },
    refreshTrain: function(index) 
    {
      document.getElementById("trainNumber").value = this.trains[index].id;
      let dat = this.trains[index].date.split("/");
      document.getElementById("trainDate").value = `${dat[2]}-${dat[1]}-${dat[0]}`;
      this.removeTrain(index);
      this.addTrain();
    }
  },
  mounted() {
    setToday();
  },
};

function with_leading_zeros(dt) {
  return (dt < 10 ? "0" : "") + dt;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  position: relative;
  text-align: left;
  padding: 5px 10px;
  margin: 7px 5px;
  background-color: #494b51;
  border-radius: 7px;
  color: cyan;
  text-decoration: none;
}

.card > h4 {
  margin: 2px;
  font-size: 24px;
}

.card > p {
  margin: 2px;
  font-size: 20px;
}


#time {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0px;
}

label {
  font-size: 18px;
}

input {
  margin: 5px 0px 8px 0px;
  width: 97%;
  background-color: #303236;
  border: none;
  font-size: 20px;
  border-radius: 5px;
  padding: 10px 5px;
  color: white;
}

.sendButton {
  background-color: #098f17;
  width: fit-content;
  height: fit-content;
  padding: 10px;
}

.voirButton {
  background-color: #303236;
  border: none;
  color: white;
  font-size: 18px;
  padding: 5px 15px;
  border-radius: 8px;
}

.icon-tabler-heart-broken
{
  position: absolute;
  top: 2px;
  right: 5px;
}

.icon-tabler-refresh
{
  position: absolute;
  right: 5px;
  bottom: 10px;
}

.modal {
  display: block; /* Hidden by default */
  position: absolute;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
  overflow: auto;
}

/* Modal Content */
.modal-content {
  background-color: #303236;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.arrets {
  margin: 15px 5px;
}

.arrets > p {
  margin: 0px;
  font-size: 24px;
}

.arrets > .arretTime {
  font-size: 18px;
  margin-left: 65px;
}
</style>
